import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Loading from "./pages/loading";
import Landing from "./pages/Landing";

import SimpleLayout from "./components/Layout/SimpleLayout";

import HomePage from "./pages/HomePage";

import "./App.css";

const App = () => {
  return (
    <Router>
      <Routes>
      

        <Route
          path="/"
          element={
            <SimpleLayout>
              <Loading />
            </SimpleLayout>
          }
        />
        
      </Routes>
    </Router>
  );
};

export default App;
